import React, { FC, useContext, useLayoutEffect, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import { useHistory } from 'react-router-dom';
import CreateFeedItemInput, { CreateFeedItemInputProps } from '@src/basic-components/CreateFeedItemInput/CreateFeedItemInput';
import PostList from '@src/components/Post/PostList/PostList';
import storageService from '@src/services/storage-service';
import { isUserJoinedToToken } from '@src/model/user/helpers';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import JoinButtonModal from '@src/components/JoinButtonModal/JoinButtonModal';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { ensurePostUrl } from '@src/model/upvote/helpers';
import PartnerList from '@src/components/Token/PartnerList/PartnerList';
import PostsTabPinnedPosts from './PinnedPosts';
import Text from '@creator/ui/components/Text/Text';
import { getFlairNameById } from '@src/model/token/helpers';
import MobileMiddleCenterAd from './MobileMiddleCenterAd';
import { isMobile } from '@src/utils/utils';
import TopicsBar from './TopicsBar';

export interface PostsTabProps {
    enableMultiline?: boolean;
    tokenName: string;
    className?: string;
    flairId: string;
    backLinkUrl?: string;
}

const TokenPagePostsTab: FC<PostsTabProps> = props => {
    const { tokenName, flairId } = props;
    const history = useHistory();
    const { t } = useTranslation();

    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);
    const { handleModal } = useContext<UseModal>(ModalContext);
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);

    useLayoutEffect(() => {
        return () => {
            storageService.memoryStorage.set(`${tokenName}_postListScrollPosition`, window.scrollY.toString());
        };
    }, []);
    
    function onFlairClick(_flairId: string): void {
        if (flairId && _flairId === flairId)
            return history.push(`/${ensureTokenUrl(tokenName)}`);
        return history.push(`/${ensureTokenUrl(tokenName)}/${_flairId}`);
    }

    function goToCreatePost() {
        if (isLoggedIn && !isUserJoinedToToken(tokenName))
            return handleModal(<JoinButtonModal tokenName={tokenName} />);
        history.push(`/bbs/${ensureTokenUrl(tokenName, false)}/posts/create`);
    }

    const onCreatePostButtonClick = useCallback(() => {
        goToCreatePost();
    }, [tokenName, isLoggedIn]);

    const onPostClick = useCallback((e, postId) => {
        history.push(`/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}`);
    }, [tokenName]);

    function renderCreateFeedItemInput() {
        const props: CreateFeedItemInputProps = {
            onIinputClick: goToCreatePost,
            onIconClick: goToCreatePost,
            tokenName,
            className: 'mx-2 lg:mx-0'
        };
        return <CreateFeedItemInput {...props} />;
    }

    function renderPartnerList() {
        return <PartnerList tokenName={tokenName} />;
    }

    function renderCommunityPostListSeparator() {
        return (
            <div className="p-4 text-lg font-medium text-center text-gray-500 border border-gray-200 dark:border-gray-700">
                <Text>{t('tokenPage.postsTab.communityPostList.title', { tokenName: token?.title || tokenName })}</Text>
            </div>
        );
    }

    function renderPinnedPosts() {
        if (Boolean(flairId)) return null;
        return <PostsTabPinnedPosts onPostClick={onPostClick} className="" tokenName={tokenName} />;
    }

    function renderPartnerPostList() {
        return (
            <PostList
                loadPageSize={5}
                excludePinnedPosts={true}
                onPostClick={onPostClick}
                className=""
                enableAds={true}
                isPartnerFilterEnabled={true}
                tokenName={tokenName}
                flairName={getFlairNameById(flairId, tokenName)}
                isInfiniteScrollEnabled={false}
                loadMoreButtonText={t('partnerPostList.loadMoreButton')}
                onCreatePostButtonClick={onCreatePostButtonClick}
            />
        );
    }

    function renderTopicsBar() {
        if (!token) return null;
        return <TopicsBar tokenName={tokenName} activeFlairs={token.activeFlairs} flairId={flairId} onFlairClick={onFlairClick} />;
    }

    function renderCommunityPostList() {
        return (
            <div className="bg-primary-50 dark:bg-primary-900">
                {renderCommunityPostListSeparator()}
                <PostList
                    className="-mt-px"
                    loadPageSize={5}
                    onPostClick={onPostClick}
                    excludePinnedPosts={true}
                    enableAds={false}
                    tokenName={tokenName}
                    flairName={getFlairNameById(flairId, tokenName)}
                    isInfiniteScrollEnabled={false}
                    onCreatePostButtonClick={onCreatePostButtonClick}
                />
            </div>
        );
    }

    return (
        <div className="space-y-4">
            {renderPartnerList()}
            {renderCreateFeedItemInput()}
            {renderTopicsBar()}
            {renderPinnedPosts()}
            {isMobile() && <MobileMiddleCenterAd tokenName={tokenName} />}
            {renderPartnerPostList()}
            {renderCommunityPostList()}
        </div>
    );
};

export default memo(TokenPagePostsTab);
