import React, { FC, lazy, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import { ensureTokenUrl, getDomainConfigValue } from '@src/model/config/helpers';
import Icon from '@src/basic-components/Icon/Icon';
import { getUrlPath } from '@src/utils/url-utils/url-utils';
import Logo from '@src/basic-components/Logo/Logo';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import Link from '@src/basic-components/Link/Link';
import BellNotification from './BellNotification/BellNotification';
import Button from '@creator/ui/components/Button/ButtonV2';

const MainMenu = lazy(() => import('./MainMenu/MainMenu'));
export interface HeaderProps {
    className?: string;
    tokenName?: string;
    showSearchButton?: boolean;
    showLoginButton?: boolean;
    hideSearch?: boolean;
}

const Header: FC<HeaderProps> = props => {
    const { showSearchButton = true, showLoginButton = true, tokenName, className } = props;

    const isAuthenticating = useStoreState(state => state.user.isAuthenticating);
    const history = useHistory();
    const { t } = useTranslation();

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const myUser = useStoreState(state => state.user.myUser, storeEqualityFn);

    const onLoginClick = useCallback((): void => {
        history.push(`/bbs/login?next=${getUrlPath()}`);
    }, []);

    const onNotificationClick = useCallback((): void => {
        history.push('/bbs/notifications');
    }, []);

    const onStakingClick = useCallback(() => {
        const stakingUrl = 'https://staking.rtb.io';
        window.open(stakingUrl);
    }, []);

    function getLogoLink() {
        return getDomainConfigValue('logoLink') || '/';
    }

    function renderLogo() {
        return (
            <div className="cursor-pointer shrink-0" style={{ height: getDomainConfigValue('logoMaxHeight') }}>
                <Link externalLinkTarget="_self" to={getLogoLink()} className="flex h-full">
                    <Logo className="w-auto transition" />
                </Link>
            </div>
        );
    }

    function renderLoginButton() {
        if (isAuthenticating || isLoggedIn || !showLoginButton) return null;
        return (
            <Button size="xs" className=""
                onClick={onLoginClick} >
                {t('header.loginButton')}
            </Button>
        );
    }

    function renderNotificationButton() {
        if (!myUser) return null;

        return (
            <BellNotification
                className="flex items-center justify-center text-2xl cursor-pointer"
                onClick={onNotificationClick}
            />
        );
    }

    function renderStakingButton() {
        if (!getDomainConfigValue('enableStakingButton')) return null;
        return (
            <Icon
                onClick={onStakingClick}
                name="cup"
                className="hidden text-2xl cursor-pointer lg:block text-gray-50"
            />
        );
    }

    function renderSearchIcon() {
        if (!showSearchButton || !tokenName) return;
        return <Icon onClick={()=> history.push(`/bbs/${ensureTokenUrl(tokenName, false)}/search`)} name="search" className="text-2xl cursor-pointer text-gray-50" />;
    }

    return (
        <div className={`h-9 lg:h-11 transition sticky top-0 z-20 shadow-sm dark:shadow-gray-800 bg-gray-950 ${className}`}>
            <div className="container flex items-center h-full px-2 lg:px-0 lg:flex">
                {renderLogo()}
                <div className="flex items-center justify-end flex-1 mx-2 mr-2 space-x-2 lg:mr-3 lg:space-x-3 min-w-max">
                    {renderStakingButton()}
                    {renderSearchIcon()}
                    {renderNotificationButton()}
                    {renderLoginButton()}
                </div>
                <MainMenu tokenName={tokenName} />
            </div>
        </div>
    );
};

export default memo(Header, propsEqualityFn);
