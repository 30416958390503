import React, { FC, useState, useEffect, Fragment, memo } from 'react';
import { isMobile, noop } from '@src/utils/utils';
import { useTranslation } from 'react-i18next';
import { Post, Post as PostModel } from '@creator/sdk/modules/upvote/upvote.model';
import { PostListFilterBy, PostListOrderBy } from '@creator/sdk/modules/config/config.model';
import { GetPostsFilterBy, GetPostsOrderBy } from '@creator/sdk/modules/upvote/upvote.service';
import { getYahooAdByLocation, getPostListDefaultFilterBy, getPostListDefaultOrderBy, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';
import storageService from '@src/services/storage-service';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import PostListEmptyState from '../PostListEmpyState/PostListEmpyState';
import Text from '@creator/ui/components/Text/Text';
import Button from '@creator/ui/components/Button/ButtonV2';
import PostCard from '../PostCard/PostCard';
import { getPostListDayFilter, getPostListMonthFilter, getPostListWeekFilter, isResponsePost } from '@src/model/upvote/helpers';
import useDidUpdateEffect from '@src/hooks/use-did-update-effect';
import { DocumentSnapshot } from '@creator/sdk/modules/db/db.model';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentPathAndSearch } from '@src/utils/url-utils/url-utils';
import { EditorViewOnlySummarizedViewProps } from '@src/basic-components/EditorViewOnly/SummarizedView/SummarizedView';
import { cn } from '@creator/ui/utils/ui';
import GoogleAdSlot from '@src/components/GoogleAdSlot/GoogleAdSlot';


export type UserFilter = 'publishedPosts' | 'currentlyUpvote' | 'outUpvoted' | 'deletedPosts';

export interface PostListProps {
    className?: string;
    tokenName: string;
    isLoading?: boolean;
    onCreatePostButtonClick?: () => void;
    onPostClick?: (e: React.MouseEvent<HTMLElement>, postId: string, post?: Post) => void;
    showPinnedPosts?: boolean;
    userIdFilter?: string;
    userFilter?: UserFilter;
    userDisplayName?: string;
    enableAds?: boolean;
    viewDeletedPosts?: boolean;
    viewDeletedByPublisher?: boolean;
    initialFilterBy?: PostListFilterBy;
    initialOrderBy?: PostListOrderBy;
    flairName?: string;
    isRemoveSort?: boolean;
    excludePinnedPosts?: boolean;
    loadPageSize?: number;
    isPartnerFilterEnabled?: boolean;
    loadMoreButtonText?: string;
    isInfiniteScrollEnabled?: boolean; // false - for load more button, true - for infinte scroll
}

const PostList: FC<PostListProps> = props => {
    const { isPartnerFilterEnabled = false, excludePinnedPosts = false, loadPageSize = 20, isInfiniteScrollEnabled = true, onPostClick = noop, userDisplayName, flairName, viewDeletedPosts, userIdFilter, userFilter, tokenName, className, viewDeletedByPublisher } = props;

    const { t } = useTranslation();
    const history = useHistory();

    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);
    const [sortBy, setSortBy] = useState(getDefaultPostListOrderBy());
    const [filterBy, setFilterBy] = useState(getDefaultPostListFilterBy());

    const postSearchResult = useStoreState(state => state.upvote.getPostSearchResult(tokenName), storeEqualityFn);
    const partnerPostSearchResult = useStoreState(state => state.upvote.getPartnerPostSearchResult(tokenName), storeEqualityFn);
    const userPostSearchResult = useStoreState(state => state.upvote.getUserPostSearchResult, storeEqualityFn);
    const posts = getSearchResult();
    const unPinnedPosts = getUnPinnedPostSearchResult();

    const postSearchResultLastDoc = useStoreState(state => state.upvote.postSearchResultLastDoc, storeEqualityFn);
    const partnerPostSearchResultLastDoc = useStoreState(state => state.upvote.partnerPostSearchResultLastDoc, storeEqualityFn);
    const userPostSearchResultLastDoc = useStoreState(state => state.upvote.userPostSearchResultLastDoc, storeEqualityFn);

    const searchResultLastDoc = getSearchResultLastDoc();

    const setPostSearchResult = useStoreActions(actions => actions.upvote.setPostSearchResult);
    const setPostSearchResultLastDoc = useStoreActions(actions => actions.upvote.setPostSearchResultLastDoc);
    const setUserPostSearchResult = useStoreActions(actions => actions.upvote.setUserPostSearchResult);
    const setUserPostSearchResultLastDoc = useStoreActions(actions => actions.upvote.setUserPostSearchResultLastDoc);
    const setPartnerPostSearchResult = useStoreActions(actions => actions.upvote.setPartnerPostSearchResult);
    const setPartnerPostSearchResultLastDoc = useStoreActions(actions => actions.upvote.setPartnerPostSearchResultLastDoc);

    const loadPosts = useStoreActions(actions => actions.upvote.loadPosts);

    const [isLoadingPosts, setIsLoadingPosts] = useState(false);
    const [hasMorePosts, setHasMorePosts] = useState(true);

    useEffect(() => {
        if (!tokenName || posts.length > 0) return; // skip load if there are posts already

        const filterBy = getFilterBy();
        const orderBy = getOrderBy();
        _loadPosts(filterBy, orderBy, null);
    }, [tokenName]);

    useDidUpdateEffect(() => {
        onFilterChanged();
    }, [tokenName, filterBy, sortBy, viewDeletedPosts, userIdFilter, userFilter, flairName, viewDeletedByPublisher]);

    useEffect(() => {
        const lsKey = `${tokenName}_postListScrollPosition`;
        const scrollToPostPosition = storageService.memoryStorage.get(lsKey);

        if (posts.length > 0 && scrollToPostPosition) {
            window.scrollTo(0, Number(scrollToPostPosition));
            storageService.memoryStorage.remove(lsKey);
            return;
        }
    }, []);

    useEffect(() => {
        return () => {
            if (viewDeletedPosts) clearSearchResult(); // clear serach result if current filter is not the main published posts
        };
    }, [viewDeletedPosts]);

    function getResponseFullPageBackLinkLabel(): string {
        if (userDisplayName) return t('backToUserPage', { username: userDisplayName });

        return t('backToTokenPage', { tokenName });
    }

    function getSearchResult() {
        if (userIdFilter) return userPostSearchResult;
        if (isPartnerFilterEnabled) return partnerPostSearchResult;
        return postSearchResult;
    }

    function getSearchResultLastDoc() {
        if (userIdFilter) return userPostSearchResultLastDoc[tokenName];
        if (isPartnerFilterEnabled) return partnerPostSearchResultLastDoc[tokenName];
        return postSearchResultLastDoc[tokenName];
    }

    function getUnPinnedPostSearchResult(): (PostModel | undefined)[] {
        const searchResult = getSearchResult();
        if (excludePinnedPosts) return searchResult.filter(post => !post?.isPinned); // un pinned posts only
        return searchResult;
    }

    function onResponseFullScreenButtonClick(e: React.MouseEvent<HTMLElement>, postId: string): void {
        e.preventDefault();
        e.stopPropagation();
        history.push(`/bbs/response/${postId}?backLinkUrl=${encodeURIComponent(getCurrentPathAndSearch())}&backLinkLabel=${getResponseFullPageBackLinkLabel()}`);
    }

    function clearSearchResult(): void {
        if (userIdFilter) {
            setUserPostSearchResult([]);
            setUserPostSearchResultLastDoc({ tokenName, lastDoc: {} });
            return;
        }

        if (isPartnerFilterEnabled) {
            setPartnerPostSearchResult({ tokenName, searchResult: [] });
            setPartnerPostSearchResultLastDoc({ tokenName, lastDoc: {} });
            return;
        }

        setPostSearchResult({ tokenName, searchResult: [] });
        setPostSearchResultLastDoc({ tokenName, lastDoc: {} });
    }

    async function onFilterChanged(): Promise<void> {
        clearSearchResult();

        const filterBy = getFilterBy();
        const orderBy = getOrderBy();

        _loadPosts(filterBy, orderBy, null);
    }

    function getDefaultPostListOrderBy(): PostListOrderBy {
        const { initialOrderBy } = props;
        if (initialOrderBy) return initialOrderBy;

        return getPostListDefaultOrderBy();
    }

    function getDefaultPostListFilterBy(): PostListFilterBy {
        const { initialFilterBy } = props;
        if (initialFilterBy) return initialFilterBy;

        return getPostListDefaultFilterBy();
    }

    function getOrderBy(): GetPostsOrderBy[] {
        return mapSortValueToOrderBy(sortBy);
    }

    function getTimeframeFilterBy(): GetPostsFilterBy[] {
        if (sortBy !== 'totalImpressions') return [];

        if (filterBy === 'lastMonth') return getPostListMonthFilter(viewDeletedPosts);
        if (filterBy === 'lastWeek') return getPostListWeekFilter(viewDeletedPosts);
        if (filterBy === 'last24h') return getPostListDayFilter(viewDeletedPosts);

        // All time
        return [];
    }

    function getPartnerFilterBy(): GetPostsFilterBy[] {
        if (userIdFilter) return [];

        return [{ field: 'createdByWhitelistedUser', 'opStr': '==', value: isPartnerFilterEnabled }];
    }

    function getFilterBy(): GetPostsFilterBy[] {
        let filter: GetPostsFilterBy[] = [];
        if (flairName) {
            filter = filter.concat([{ field: 'flairs', 'opStr': 'array-contains', value: flairName }]);
            filter = filter.concat([{ field: 'status', 'opStr': '==', value: 0 }]);
        }
        else if (userIdFilter) {
            if (!viewDeletedPosts)
                filter = filter.concat([{ field: 'status', 'opStr': 'in', value: [0, 7] }]);
            else if (viewDeletedPosts && !viewDeletedByPublisher)
                filter = filter.concat([{ field: 'status', 'opStr': 'in', value: [0, 1, 3, 4] }]);

            const userFilter = getUserFilterBy();
            filter = filter.concat(userFilter);
        }
        else {
            if (viewDeletedPosts)
                filter = filter.concat([{ field: 'status', 'opStr': 'in', value: [1, 3, 4] }]);
            else
                filter = filter.concat([{ field: 'status', 'opStr': '==', value: 0 }]);
        }
        return filter.concat(getTimeframeFilterBy()).concat(getPartnerFilterBy());
    }

    function getUserFilterBy(): GetPostsFilterBy[] {
        if (!userFilter || !userIdFilter) return [];

        if (userFilter === 'publishedPosts') return [{ field: 'publisherId', opStr: '==', value: userIdFilter }];
        if (userFilter === 'currentlyUpvote') return [{ field: 'upvoterId', opStr: '==', value: userIdFilter }];
        if (userFilter === 'outUpvoted') return [{ field: 'prevUpvoters', opStr: 'array-contains', value: userIdFilter }];

        return [];
    }

    function mapSortValueToOrderBy(value: PostListOrderBy): GetPostsOrderBy[] {
        const baseOrderBy: GetPostsOrderBy[] = [];

        if (value === 'hot')
            return baseOrderBy.concat({ field: 'hotPostTime', direction: 'desc' });

        if (value === 'recentActivity')
            return baseOrderBy.concat({ field: 'lastActivity', direction: 'desc' });

        if (value === 'totalImpressions') {
            return baseOrderBy.concat(
                { field: 'totalImpressions', direction: 'desc' },
                { field: 'createdAt', direction: 'desc' }
            );
        }

        return baseOrderBy.concat({ field: 'createdAt', direction: 'desc' });
    }

    function getSaveTo() {
        if (Boolean(userIdFilter)) return 'user';
        if (isPartnerFilterEnabled) return 'partnerPosts';

        return undefined;
    }

    async function _loadPosts(filterBy: GetPostsFilterBy[], orderBy: GetPostsOrderBy[], lowerBound?: DocumentSnapshot): Promise<void> {
        setIsLoadingPosts(true);

        const { hasMore, items } = await loadPosts({
            includeResponses: true,
            includeSuperComment: true,
            tokenName,
            lowerBound: lowerBound || null,
            limit: loadPageSize,
            filterBy,
            orderBy,
            saveTo: getSaveTo()
        });

        setHasMorePosts(hasMore);
        setIsLoadingPosts(false);
    }

    const onLoadMore = useCallback(async () => {
        if (isLoadingPosts) return; // https://github.com/CassetteRocks/react-infinite-scroller/issues/239#issuecomment-610990283

        const lastPost = posts[posts.length - 1];
        if (!lastPost || !sortBy) return;

        const filterBy = getFilterBy();
        const orderBy = getOrderBy();

        _loadPosts(filterBy, orderBy, searchResultLastDoc);
    }, [isLoadingPosts, posts, searchResultLastDoc, flairName]);

    function renderEmptyState() {
        const { onCreatePostButtonClick = noop } = props;

        if (isLoadingPosts) return null;

        return (
            <PostListEmptyState isStory={isPartnerFilterEnabled} tokenName={tokenName} className="-mt-px" onCreatePostButtonClick={onCreatePostButtonClick} />
        );
    }

    function renderPost(post: PostModel | undefined) {
        if (!post || !token) return null;

        let editorProps: Partial<EditorViewOnlySummarizedViewProps> = {};
        if (isResponsePost(post)) {
            editorProps = {
                editorViewOnlyVideoToolProps: {
                    onResponseFullScreenButtonClick: e => onResponseFullScreenButtonClick(e, post.id)
                },
                editorViewOnlyAudioToolProps: {
                    onResponseFullScreenButtonClick: e => onResponseFullScreenButtonClick(e, post.id)
                }
            };
        }

        if (isPartnerFilterEnabled)
            editorProps = { ...editorProps, imagePreviewProps: { className: '-mx-3 -mt-3 lg:-mx-5' } };

        return (
            <PostCard
                key={post.id}
                className="bg-transparent dark:bg-transparent"
                editorProps={editorProps}
                tokenName={tokenName}
                onClick={onPostClick}
                postId={post.id} />
        );
    }

    function renderLoadMoreButton() {
        const { loadMoreButtonText = '' } = props;

        if (!hasMorePosts) return;

        return (
            <Button isLoading={isLoadingPosts} size="md" className="w-full mt-3" onClick={onLoadMore}>
                <Text>{loadMoreButtonText || t('postList.loadMoreButton')}</Text>
            </Button>
        );
    }

    function renderAd(i = 0) {
        const location = isMobile() ? 'main_mw_mid_center' : 'main_dt_mid_center_card';
        const yahooAd = getYahooAdByLocation(location);
        if (!yahooAd) return;

        const { adUnitPath, divId } = yahooAd;
        return (
            <div key={`ad-${i}`} className="py-2">
                <GoogleAdSlot targeting={getYahooAdTargetingByTokenName(tokenName)} className="mx-auto" adUnitPath={adUnitPath} sizes={[300, 250]} divId={divId} index={i} />
            </div>
        );
    }

    function renderPosts() {
        if (unPinnedPosts.length === 0) return renderEmptyState();

        const { enableAds } = props;

        return (
            <Fragment>
                <div className="-space-y-px">
                    {unPinnedPosts.map((post, index) =>
                        <Fragment key={post?.id}>
                            {renderPost(post)}
                            {/* Render an ad every 3rd post */}
                            {enableAds && (index + 1) % 3 === 0 && renderAd((index + 1) / 3)}
                        </Fragment>
                    )}
                </div>
                {!isInfiniteScrollEnabled && renderLoadMoreButton()}
            </Fragment>
        );
    }

    return (
        <div className={cn('transition', className)}>
            {renderPosts()}
        </div>
    );
};

export default memo(PostList);
