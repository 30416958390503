import React, { FC, lazy, PropsWithChildren, Suspense, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import AuthRequiredRoute from './components/Auth/AuthRequiredRoute/AuthRequiredRoute';
import NoAuthRequiredRoute from './components/Auth/NoAuthRequiredRoute/NoAuthRequiredRoute';
import NetworkAdminRequiredRoute from './components/Auth/NetworkAdminRequiredRoute/NetworkAdminRequiredRoute';
import { noop } from './utils/utils';
import { getUrlMappingTokenRouteBasePaths } from './model/config/helpers';
import { removeTrailingSlashes } from '@creator/ui/utils/url-utils';
import { pushToDataLayer } from './services/gtmService';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import HomepageWrapper from './pages/HomePage/HomepageWrapper';
import TokenPage from './pages/TokenPage/TokenPage';

// Lazy load all page components
const AdsDebugPage = lazy(() => import('./pages/AdsDebugPage/AdsDebugPage'));
// const HomepageWrapper = lazy(() => import('./pages/HomePage/HomepageWrapper'));
// const TokenPage = lazy(() => import('./pages/TokenPage/TokenPage'));
const BbsDirectoryPage = lazy(() => import('./pages/BbsDirectoryPage/BbsDirectoryPage'));
const CreateAccountPage = lazy(() => import('./pages/CreateAccountPage/CreateAccountPage'));
const LoginPage = lazy(() => import('./pages/Login/Login'));
const SignInWithEmailLinkPage = lazy(() => import('./pages/SignInWithEmailLink/SignInWithEmailLink'));
const FiatInSuccessPage = lazy(() => import('./pages/FiatInSuccess/FiatInSuccess'));
const CreateTokenPage = lazy(() => import('./pages/CreateTokenPage/CreateTokenPage'));
const ChangePasswordWithEmailLinkPage = lazy(() => import('./pages/ChangePasswordWithEmailLink/ChangePasswordWithEmailLink'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage/NotificationsPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage/NotFoundPage'));
const UserPageById = lazy(() => import('./pages/UserPage/UserPageById'));
const SearchPage = lazy(() => import('./pages/SearchPage/SearchPage'));
const AccountSettingsPage = lazy(() => import('./pages/AccountSettingsPage/AccountSettingsPage'));
const ForumPickerPage = lazy(() => import('./pages/ForumPickerPage/ForumPickerPage'));
const NotificationSettingsPage = lazy(() => import('./pages/NotificationSettingsPage/NotificationSettingsPage'));
const DirectMessagePage = lazy(() => import('./pages/DirectMessagePage/DirectMessagePage'));
const CreateExternalArticleCommentPage = lazy(() => import('./pages/CreateExternalArticleCommentPage/CreateExternalArticleCommentPage'));
const UserPage = lazy(() => import('./pages/UserPage/UserPage'));
const BuyTokenPage = lazy(() => import('./pages/BuyTokenPage/BuyTokenPage'));
const EditPostPage = lazy(() => import('./pages/EditPostPage/EditPostPage'));
const CreatePostPage = lazy(() => import('./pages/CreatePostPage/CreatePostPage'));
const AdminSettingsPage = lazy(() => import('./pages/AdminSettingsPage/AdminSettingsPage'));
const PostResponsePage = lazy(() => import('./pages/PostResponsePage/PostResponsePage'));
const PostResponseThreadPage = lazy(() => import('./pages/PostResponsePage/PostResponseThreadPage'));
const DomainMainMenuEditorPage = lazy(() => import('./pages/NetworkAdminDashboard/DomainMainMenuEditorPage/DomainMainMenuEditorPage'));
const NetworkAdminDashboard = lazy(() => import('./pages/NetworkAdminDashboard/NetworkAdminDashboard'));

export interface RoutesProps {
    onPathChange?: (pathname: string) => void;
}

export const Routes: FC<PropsWithChildren<RoutesProps>> = props => {
    const location = useLocation();

    function trackPageView() {
        pushToDataLayer({ event: 'non__article_view', path: location.pathname });
    }

    useEffect(() => {
        const { onPathChange = noop } = props;
        onPathChange(location.pathname);
        if (
            location.pathname.startsWith('/bbs') ||
            location.pathname.includes('/author/') ||
            location.pathname.includes('/admin-settings/')
        )
            trackPageView();

    }, [location.pathname, props]);

    function renderTokenRoutes() {
        const tokenRouteBasePaths = getUrlMappingTokenRouteBasePaths();

        return tokenRouteBasePaths.map(tokenRouteBasePath => {
            // Use the utility function to clean the base path
            const cleanedBasePath = removeTrailingSlashes(tokenRouteBasePath);

            // Construct the final path
            const basePath = `${cleanedBasePath}/:tokenSlugOrName`;
            return (
                <Route key={tokenRouteBasePath} path={basePath}>
                    <Switch>
                        {/* Most specific routes first */}
                        <AuthRequiredRoute exact path={`${basePath}/admin-settings/:tab?`} component={AdminSettingsPage} />
                        <Route exact path={`${basePath}/tab/:tab`} component={TokenPage} />
                        <Route exact path={`${basePath}/author/:slugOrDisplayName/message`} component={DirectMessagePage} />
                        <Route exact path={`${basePath}/author/:slugOrDisplayName/:tab?`} component={UserPage} />

                        <Route exact path={`${basePath}/:identifier`} component={TokenPage} />
                        <Route exact path={`${basePath}/:flairName/:identifier`} component={TokenPage} />
                        <Route exact path={`${basePath}/:identifier/comment/:commentId/:reply?/:replyId?`} component={TokenPage} />
                        <Route exact path={`${basePath}/:flairName/:identifier/comment/:commentId/:reply?/:replyId?`} component={TokenPage} />

                        {/* General routes */}
                        <Route exact path={`${basePath}`} component={TokenPage} />
                    </Switch>
                </Route>
            );
        });
    }

    return (
        <Suspense fallback={null}>
            <Switch>
                <Route exact path="/" component={HomepageWrapper} />
                <Route exact path="/bbs" component={BbsDirectoryPage} />
                <Route exact path="/bbs/thread/:threadEndResponseId/:responseId?" component={PostResponseThreadPage} />
                <Route exact path="/bbs/response/:responseId" component={PostResponsePage} />
                <AuthRequiredRoute exact path="/bbs/create-token" component={CreateTokenPage} />
                <Route exact path="/bbs/search/:type?" component={SearchPage} />
                <Route exact path="/bbs/:tokenSlugOrName/search/:type?" component={SearchPage} />
                <Route exact path="/bbs/404" component={NotFoundPage} />
                <Route exact path="/bbs/picker" component={ForumPickerPage} />
                <Route exact path="/bbs/email-sign-in" component={SignInWithEmailLinkPage} />
                <Route exact path="/bbs/email-change-password" component={ChangePasswordWithEmailLinkPage} />
                <AuthRequiredRoute exact path="/bbs/my-account/settings" component={AccountSettingsPage} />
                <Route exact path="/author/:id" component={UserPageById} />
                <Route exact path="/bbs/singup" component={CreateAccountPage} />
                <NoAuthRequiredRoute exact path="/bbs/login" component={LoginPage} />
                <Route exact path="/bbs/fiat-in-success" component={FiatInSuccessPage} />
                <AuthRequiredRoute exact path="/bbs/buy-rtb" component={BuyTokenPage} />
                <AuthRequiredRoute exact path="/bbs/notifications/:tab?" component={NotificationsPage} />
                <AuthRequiredRoute exact path="/bbs/my-profile/settings/notifications" component={NotificationSettingsPage} />
                <Route exact path="/bbs/:tokenSlugOrName/posts/create" component={CreatePostPage} />
                <AuthRequiredRoute exact path="/bbs/:tokenSlugOrName/posts/:postSlugOrId/edit" component={EditPostPage} />
                <AuthRequiredRoute exact path="/bbs/external-article-comment" component={CreateExternalArticleCommentPage} />
                <NetworkAdminRequiredRoute exact path="/bbs/network-admin-dashboard" component={NetworkAdminDashboard} />
                <NetworkAdminRequiredRoute exact path="/bbs/network-admin-dashboard/domain-main-menu-editor" component={DomainMainMenuEditorPage} />
                <Route exact path="/bbs/ad-debug-page" component={AdsDebugPage} />
                {renderTokenRoutes()}

                {/* Fallback route */}
                <Route component={NotFoundPage} />
            </Switch>
        </Suspense>
    );
};
