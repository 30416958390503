import store from '@src/store';
import { Post, PostComment } from '@creator/sdk/modules/upvote/upvote.model';
import creatorSdk from '@src/services/creator-sdk';
// import { getCommentReaction, getPostReaction } from '../user/helpers';
import { GetPostsFilterBy } from '@creator/sdk/modules/upvote/upvote.service';
import { padTwoDigits } from '@src/utils/number-utils/number-utils';
import { getMyUser, getUserId } from '../user/helpers';
import { EditorJSMediaBlock } from '@src/basic-components/Media/Media';
import { getBaseUrl } from '@src/config';
import { PostStatus } from './permission';
import { ensureTokenUrl } from '../config/helpers';
import { slugify } from '@creator/ui/utils/url-utils';

export function getPost(postId: string): Post | undefined {
    return store.getState().upvote.getPost(postId);
}

export function getPostBySlugUrlOrId(postSlugOrId: string): Post | undefined {
    return store.getState().upvote.getPostBySlugUrlOrId(postSlugOrId);
}

export function getPostsByPublisherId(publisherId: string, tokenName?: string) {
    const posts = store.getState().upvote.posts;

    const userPosts: Post[] = [];

    for (const postId in posts) {
        const post = posts[postId];
        if (post.publisherId !== publisherId) continue;
        if (tokenName && post.tokenName !== tokenName) continue;

        userPosts.push(post);
    }

    return userPosts;
}

export function getCommentsByPublisherId(publisherId: string, tokenName?: string) {
    const comments = store.getState().upvote.postComments;

    const userComments: PostComment[] = [];

    for (const commentId in comments) {
        const comment = comments[commentId];
        if (comment.publisherId !== publisherId) continue;
        if (tokenName && comment.tokenName !== tokenName) continue;

        userComments.push(comment);
    }

    return userComments;
}

export function getPostComment(commentId: string): PostComment | undefined {
    return store.getState().upvote.getPostComment(commentId);
}

export function getPostCommentReply(commentId: string, replyId: string): PostComment | undefined {
    return store.getState().upvote.getPostCommentReply(commentId, replyId);
}

export function getPostTitleMaxLength(): number {
    return 150;
}

export function isPostDeleted(postId: string): boolean {
    const post = getPost(postId);
    if (!post) return false;

    return [1, 2, 3, 4].includes(post.status);
}

export function isPendingPost(postId: string): boolean {
    const post = getPost(postId);
    if (!post) return false;

    return post.status === 5;
}

export function isPendingTranscriptPost(postId: string): boolean {
    const post = getPost(postId);
    if (!post) return false;

    return post.status === 6;
}

export function isResponsePost(postOrPostId: string | Post): boolean {
    const _post = typeof postOrPostId === 'object' ? postOrPostId : getPost(postOrPostId);
    if (!_post) return false;
    const { postTypes = [] } = _post;
    return postTypes.includes('response');
}

export function isResponseAblePost(postId: string): boolean {
    const post = getPost(postId);
    if (!post) return false;

    return true;
}

export function isAnonymousPost(postId: string): boolean {
    const post = getPost(postId);
    if (!post) return false;

    return post.publisherName === 'anonymous';
}

export function isCommentDeleted(commentId: string): boolean {
    const comment = getPostComment(commentId);
    if (!comment) return false;

    return [1, 2, 3, 4].includes(comment.status);
}

export function getPostReactionCount(postId: string, reactionName: string): number {
    const post = getPost(postId);
    if (!post) return 0;
    if (!post.reactionsCount) return 0;

    return post.reactionsCount[reactionName] || 0;
}

export function getPostContent(postId: string): EditorJS.OutputData | null {
    const post = getPost(postId);
    if (!post) return null;

    // Ignore invalid jsons
    let postContent;
    try {
        postContent = JSON.parse(post.postContent);
    } catch (error) {
        return null;
    }

    return postContent;
}

export function getCommentReactionCount(commentId: string, reactionName: string, replyId?: string): number {
    const comment = replyId ? getPostCommentReply(commentId, replyId) : getPostComment(commentId);
    if (!comment) return 0;
    if (!comment.reactionsCount) return 0;

    return comment.reactionsCount[reactionName] || 0;
}

export function isReactedToPost(userId: string, postId: string, reactionName: string): boolean {
    if (!userId) return false;
    const myUser = getMyUser();
    const post = getPost(postId);
    if (!post || !post.reactionsUsers) return false;

    let isReactedByForwardAccount = false;

    if (!post.reactionsUsers[reactionName]) return false;
    if (myUser && myUser.ForwardedFromArray) {
        myUser.ForwardedFromArray.map(forwardedId => {
            if (Boolean(post.reactionsUsers[reactionName].find(_userId => _userId === forwardedId)))
                isReactedByForwardAccount = true;
        });
    }
    if (isReactedByForwardAccount) return true;
    return Boolean(post.reactionsUsers[reactionName].find(_userId => _userId === userId));
}

export function isReactedToComment(userId: string, commentId: string, reactionName: string, replyId?: string): boolean {
    if (!userId) return false;
    const myUser = getMyUser();
    const comment = replyId ? getPostCommentReply(commentId, replyId) : getPostComment(commentId);
    if (!comment || !comment.reactionsUsers) return false;
    if (!comment.reactionsUsers[reactionName]) return false;

    let isReactedByForwardAccount = false;
    if (myUser && myUser.ForwardedFromArray) {
        myUser.ForwardedFromArray.map(forwardedId => {
            if (Boolean(comment.reactionsUsers[reactionName].includes(forwardedId)))
                isReactedByForwardAccount = true;

        });
    }
    if (isReactedByForwardAccount) return true;
    return Boolean(comment.reactionsUsers[reactionName].find(_userId => _userId === userId));
}


export function getPostListMonthFilter(currentDateOnly = false): GetPostsFilterBy[] {
    const currentDate = creatorSdk.timeModule.getServerTime().toDate();
    const currentDay = currentDate.getDate(); // day of month

    const value = `${currentDate.getFullYear()}-${padTwoDigits(currentDate.getMonth() + 1)}`;

    if (currentDay >= 15 || currentDateOnly)
        return [{ field: 'publishedYearAndMonth', 'opStr': '==', value }];

    // if the day of the month is before the 15th get posts from last month too
    currentDate.setMonth(currentDate.getMonth() - 1);
    const prevValue = `${currentDate.getFullYear()}-${padTwoDigits(currentDate.getMonth() + 1)}`;
    return [{ field: 'publishedYearAndMonth', 'opStr': 'in', value: [prevValue, value] }];
}

export function getPostListWeekFilter(currentDateOnly = false): GetPostsFilterBy[] {
    const millisecondsInWeek = creatorSdk.timeModule.getMillisecondsInWeek();

    const currentDate = creatorSdk.timeModule.getServerTime().toDate();
    const currentWeekDay = currentDate.getDay();
    const value = Math.floor(currentDate.getTime() / millisecondsInWeek);

    if (currentWeekDay < 4 || currentDateOnly)
        return [{ field: 'publishedWeekTimestamp', 'opStr': '==', value: value }];

    // if the week day is Thursday, Friday or Saturday get posts from last week too. (firebase starts counting the week from thursday)
    return [{ field: 'publishedWeekTimestamp', 'opStr': 'in', value: [value - 1, value] }];
}

export function getPostListDayFilter(currentDateOnly = false): GetPostsFilterBy[] {
    const millisecondsInDay = creatorSdk.timeModule.getMillisecondsInDay();

    const currentDate = creatorSdk.timeModule.getServerTime().toDate();
    const currentHour = currentDate.getHours();
    const value = Math.floor(currentDate.getTime() / millisecondsInDay);

    if (currentHour >= 12 || currentDateOnly)
        return [{ field: 'publishedDayTimestamp', 'opStr': '==', value }];

    // if server time < noon => get posts from yesterday too
    return [{ field: 'publishedDayTimestamp', 'opStr': 'in', value: [value - 1, value] }];
}

export function getMediaPoster(url: string): string {
    const lastSegment = url.split('/').pop();
    if (lastSegment?.includes('.')) {
        const originalType = url.split('.').pop() || ''; // get file extensions
        return url.replace(originalType, 'jpeg?alt=media');
    }

    return url.replace('?alt=media', '.jpeg?alt=media');
}

export function hasMediaBlock(blocks: EditorJS.OutputBlockData[]): boolean {
    return Boolean(blocks.find(block => block.type === 'video' || block.type === 'audio'));
}

export function hasNewlyUploadedMediaBlock(blocks: EditorJS.OutputBlockData[]): boolean {
    return Boolean(blocks.find(block => block.type === 'video' && block.data.url.includes('newBlockId='))); // block.type === 'audio'
}

export function getMediaBlockData(type: EditorJSMediaBlock, url: string, thumbnailUrl: string): EditorJS.OutputBlockData['data'] {
    if (type === 'video') {
        return {
            'url': url,
            'caption': '',
            'autoplay': false,
            'controls': true,
            'muted': false,
            'stretched': true,
            'width': 0,
            'height': 0,
            'image': {
                'url': thumbnailUrl
            }
        };
    }
    // Audio
    return {
        'url': url,
        'caption': '',
        'controls': true,
        'image': {
            'url': thumbnailUrl
        }
    };
}

export function remmoveBlockByBlockId(blocks: EditorJS.OutputBlockData[], blockId: string): EditorJS.OutputBlockData[] {
    return blocks.filter(block => block.id !== blockId);
}

export function getPostShareUrl(tokenName: string, postId: string): string {
    const baseUrl = `${getBaseUrl()}/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}`;
    if (getUserId()) return `${baseUrl}?refBy=${getUserId()}`;
    return baseUrl;
}

export function isPostPending(postId: string): boolean {
    const post = getPost(postId);
    if (!post) return false;

    return post.status === PostStatus.PENDING;
}

export function getMainFlairName(postId: string): string {
    const post = getPost(postId);
    if (!post || !post.flairs || !post.flairs.length) return '';
    return post.flairs[0];
}

export function ensurePostUrl(postSlugOrId: string, includeFlairId = true){
    const post = getPostBySlugUrlOrId(postSlugOrId);
    if (!post || !post.slugUrl) return postSlugOrId;
    const flairName = getMainFlairName(post.id);
    if (flairName && includeFlairId) return `${slugify(flairName)}/${post.slugUrl}`;
    return post.slugUrl;
};
