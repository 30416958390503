import React, { FC, ReactNode } from 'react';
import Text from '../Text/Text';
import Button, { ButtonProps } from '../Button/ButtonV2';
import { mergeProps } from '../../utils/merge-props/merge-props';

export interface ConfirmModalProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    testId?: string;
    title: string;
    subtitle?: string;
    okButtonProps: ButtonProps;
    cancelButtonProps?: ButtonProps;
    okButtonText?: string;
    cancelButtonText?: string;
    children?: React.ReactNode;
}

const ConfirmModal: FC<ConfirmModalProps> = props => {
    const { okButtonText = 'Ok', cancelButtonText = 'Cancel', children, className } = props;

    function renderTitle(): ReactNode {
        const { title } = props;
        return (
            <Text enableMultiline={true} className="block text-xl font-bold text-center">
                {title}
            </Text>
        );
    }

    function renderSubtitle(): ReactNode {
        const { subtitle } = props;
        if (!subtitle) return null;
        return (
            <Text enableMultiline={true} className="block text-sm text-center text-gray-500">
                {subtitle}
            </Text>
        );
    }

    function renderOkButton(): ReactNode {
        const { okButtonProps } = props;
        const _props = mergeProps<[Partial<ButtonProps>, Partial<ButtonProps>]>(
            {
                size: 'md',
                color: 'primary',
                className: 'w-1/3'
            },
            okButtonProps
        );
        return (
            <Button {..._props}>
                <Text>{okButtonText}</Text>
            </Button>
        );
    }

    function renderCancelButton(): ReactNode {
        const { cancelButtonProps } = props;
        if (!cancelButtonProps) return null;
        const _props = mergeProps<[Partial<ButtonProps>, Partial<ButtonProps>]>(
            {
                size: 'md',
                color: 'base',
                className: 'w-1/3'
            },
            cancelButtonProps
        );
        return (
            <Button {..._props}>
                <Text>{cancelButtonText}</Text>
            </Button>
        );
    }

    function renderActionButtons(): ReactNode {
        // If a cancel button is provided, render both with equal flex widths.
        if (props.cancelButtonProps) {
            return (
                <div className="flex justify-center mt-10 space-x-4">
                    {renderCancelButton()}
                    {renderOkButton()}
                </div>
            );
        }
        // Otherwise, center the single ok button with a max width.
        return (
            <div className="flex justify-center mt-10 spac">
                <div className="w-full max-w-xs">
                    {renderOkButton()}
                </div>
            </div>
        );
    }

    return (
        <div className={`${className} text-gray-900 dark:text-white `}>
            <div className="space-y-2">
                {renderTitle()}
                {renderSubtitle()}
                {children}
            </div>
            {renderActionButtons()}
        </div>
    );
};

export default ConfirmModal;
